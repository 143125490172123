import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Payments from '../components/Payment.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Offerings from '../components/Offerings';
import AboutSection from '../components/AboutSection';
import WhyChooseUsSection from '../components/WhyChooseUsSection';
import ClassesScheduleSection from '../components/ClassesScheduleSection';
import Testimonials from '../components/Testimonials';
import ResourcesSection from '../components/ResourcesSection';
import ContactUsSection from '../components/ContactUsSection';
import BookingForm from '../components/BookingForm';
import NotFound from '../components/NotFound.js'; 
import Blog from "../components/Blog.js";
import Schedule from "../components/Schedule.js"
const AppRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <Offerings />
              <WhyChooseUsSection />
              <ClassesScheduleSection />
              <Testimonials />
              <ResourcesSection />
            </>
          }
        />
        <Route path="/schedule" element={<Schedule/>} /> 
        <Route path='/blog' element={<Blog/>} />
        <Route path='/payment' element={<Payments />} />
        <Route path="/about" element={<AboutSection />} />

        
        <Route path="/contact" element={<ContactUsSection />} />

        
        <Route path="/booking" element={<BookingForm />} />

        
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRoutes;
