import React, { useEffect, useRef, useState } from 'react';
import './whyChooseus.css';
import Certify from '../assets/images/certifiy.png';
// import YogaBenifit from '../assets/images/yogabenifit.png';
import YogaBenifit from '../assets/images/yogaga.png';
import Philosophy from '../assets/images/philosopy.png';

const WhyChooseUsSection = () => {
  const statsRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      });
    });

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  return (
    <div className="landing-page">
      <section className="why-us">
        <div className="certification animate-section">
          <h2 className="themecolour">Why Choose Us?</h2>
          <div className="why-us-sec">
            <img
              src={Certify}
              alt="Energy Codes Certified Master Trainer"
              className="certification-badge"
            />
            <p className="widthfix">
              We are certified yoga trainers with years of experience. At Hari Yoga, we combine traditional yoga practices with modern insights to deliver a truly enriching and healing experience for the mind and body.
            </p>
          </div>
        </div>

        <div className="stats numericsection borderhere animate-section" ref={statsRef}>
          <div>
            <h3>{animate ? <AnimatedCounter target={2500} /> : '0'}+</h3>
            <p>Active Yoga Participants</p>
          </div>
          <div>
            <h3>{animate ? <AnimatedCounter target={50} /> : '0'}+</h3>
            <p>Family Batches</p>
          </div>
          <div>
            <h3>{animate ? <AnimatedCounter target={5000} /> : '0'}+</h3>
            <p>Happy Yogis</p>
          </div>
        </div>
      </section>

      <section className="why-yoga flex-row">
        <div className="text-content animate-section">
          <h2 className='themecolour'>Why is Yoga the Need of the Hour?</h2>
          <p className='widthfix'>
            Yoga aligns your body with the natural energies of the universe, promoting physical, mental, and emotional balance. In today's fast-paced world, yoga offers a sanctuary where you can reconnect with your inner self and embrace a more sustainable, healthier lifestyle.
          </p>
        </div>
        <div className="illustration animate-section">
          <img
            src={YogaBenifit}
            alt="Illustration of yoga postures with benefits"
            className="yoga-illustration"
          />
        </div>
      </section>

      <section className="philosophy flex-row">
        <div className="text-content animate-section">
          <h2>Our Philosophy</h2>
          <p className='widthfix'>
            At Hari Yoga, we believe in creating a supportive environment where everyone can thrive. Our instructors are dedicated to helping you unlock your potential, whether you're new to yoga or an experienced practitioner. Join us in embracing a lifestyle of harmony and wellness.
          </p>
        </div>
        <div className="group-photo animate-section">
          <img
            src={Philosophy}
            alt="Group of Hari Yoga people practicing yoga together"
            className="group-photo-image"
          />
        </div>
      </section>
    </div>
  );
};

const AnimatedCounter = ({ target }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 2000; // 2 seconds for the count animation
    const increment = target / (duration / 50);

    const timer = setInterval(() => {
      start += increment;
      if (start >= target) {
        setCount(target);
        clearInterval(timer);
      } else {
        setCount(Math.ceil(start));
      }
    }, 50);

    return () => clearInterval(timer);
  }, [target]);

  return <>{count}</>;
};

export default WhyChooseUsSection;
