import React, { useState, useEffect } from "react";
import "./CustomerTestimonials.css";

const testimonials = {
  en: [
    {
      name: "Pankaj",
      batch: "Family Batch",
      review:
        "This platform has helped me grow personally and professionally. Great content and even better community.",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
    {
      name: "Smirity",
      batch: "Weekly Batch",
      review:
        "I absolutely love the way this platform is structured. Learning made simple and rewarding.",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
    {
      name: "Vandana",
      batch: "Family Batch",
      review:
        "Highly recommended! A wonderful place to learn and earn, with great support and a user-friendly interface.",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
    {
      name: "Utkarsh",
      batch: "Weekly Batch",
      review:
        "A one-stop solution for learning and earning. Love the rewards system, keeps me motivated!",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
  ],
  hi: [
    {
      name: "पंकज",
      batch: "फैमिली बैच",
      review:
        "यह प्लेटफ़ॉर्म मुझे व्यक्तिगत और पेशेवर रूप से बढ़ने में मदद कर रहा है। शानदार सामग्री और उससे भी बेहतर समुदाय।",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
    {
      name: "स्मृति",
      batch: "वीकली बैच",
      review:
        "मैं इस प्लेटफॉर्म को बिल्कुल पसंद करता हूं। यह सीखना सरल और पुरस्कृत करने वाला है।",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
    {
      name: "वन्दना",
      batch: "फैमिली बैच",
      review:
        "यह अत्यधिक सिफारिश की जाती है! यह सीखने और कमाई का एक अद्भुत स्थान है, शानदार समर्थन और उपयोगकर्ता-मित्र इंटरफ़ेस के साथ।",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
    {
      name: "उत्कर्ष",
      batch: "वीकली बैच",
      review:
        "सीखने और कमाई के लिए एक-स्टॉप समाधान। रिवॉर्ड सिस्टम पसंद है, यह मुझे प्रेरित रखता है!",
      image: "https://e7.pngegg.com/pngimages/830/234/png-clipart-christian-meditation-feeling-yoga-meditation-physical-fitness-silhouette.png",
    },
  ],
};

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials[language].length);
    }, 4000); // Slide every 4 seconds
    return () => clearInterval(interval);
  }, [language]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "hi" : "en");
  };

  return (
    <div className="testimonials-section">
      <h2 className="section-title">
        What Our <span className="highlight">Customer Says</span>
      </h2>

      <div className="language-toggle-container">
        <div
          className={`language-toggle ${language === "hi" ? "active" : ""}`}
          onClick={toggleLanguage}
        >
          <span className="english">EN</span> | <span className="hindi">हिंदी</span>
        </div>
      </div>

      <div className="testimonials-carousel">
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${activeIndex * 100}%)`,
            transition: "transform 0.5s ease-out",
          }}
        >
          {testimonials[language].map((testimonial, index) => (
            <div
              className={`testimonial-card ${index === activeIndex ? "active" : ""}`}
              key={index}
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
              />
              <div className="testimonial-content">
                <h3 className="testimonial-name">{testimonial.name}</h3>
                <p className="testimonial-batch">{testimonial.batch}</p>
                <p className="testimonial-review">{testimonial.review}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="dots-container">
        {testimonials[language].map((_, index) => (
          <span
            key={index}
            className={`dot ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
