export const savePhoneNumber = async (phoneNumber) => {
    try {
        const response = await fetch('https://hari.hariyog.com/api/save-phone/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone_number: phoneNumber }),
        });

        const data = await response.json();

        if (response.ok) {
            console.log('Phone number saved successfully:', data);
            return { success: true, message: data.message };
        } else {
            console.error('Failed to save phone number:', data);
            return { success: false, error: data };
        }
    } catch (error) {
        console.error('Error while saving phone number:', error);
        return { success: false, error: error.message };
    }
};

export const updateUserDetails = async (phoneNumber, name, age, gender) => {
    try {
        const response = await fetch('https://hari.hariyog.com/api/save-additional-info/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone_number: phoneNumber,
                name: name || 'NA', 
                age: age || 'NA',
                gender: gender || 'NA',
            }),
        });

        const data = await response.json();

        if (response.ok) {
            console.log('User details updated successfully:', data);
            return { success: true, message: data.message };
        } else {
            console.error('Failed to update user details:', data);
            return { success: false, error: data.message || 'Something went wrong.' };
        }
    } catch (error) {
        console.error('Error while updating user details:', error);
        return { success: false, error: error.message };
    }
};

export const savePopupFormData = async (name, phoneNumber, feeling, yogaPractice) => {
    try {
        const response = await fetch('https://hari.hariyog.com/api/save-popup-form/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name || 'NA',
                phone_number: phoneNumber,
                feeling: feeling,
                yoga_practice: yogaPractice,
            }),
        });

        const data = await response.json();

        if (response.ok) {
            console.log('Popup form data saved successfully:', data);
            return { success: true, message: data.message };
        } else {
            console.error('Failed to save popup form data:', data);
            return { success: false, error: data.message || 'Something went wrong.' };
        }
    } catch (error) {
        console.error('Error while saving popup form data:', error);
        return { success: false, error: error.message };
    }
};


export const saveContactFormData = async (name, email, message) => {
    try {
      const response = await fetch('https://hari.hariyog.com/api/api/contact-us/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        console.log('Contact form data saved successfully:', data);
        return { success: true, message: data.message };
      } else {
        console.error('Failed to save contact form data:', data);
        return { success: false, error: data };
      }
    } catch (error) {
      console.error('Error while saving contact form data:', error);
      return { success: false, error: error.message };
    }
  };
  