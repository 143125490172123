import React, { useState } from "react";
import "./AboutUs.css"; // Ensure your styles are in place
import yogaImage from "../assets/images/hariyogaimg.png"; 

const AboutUs = () => {
  // State to track the selected language (English or Hindi)
  const [language, setLanguage] = useState("en");

  // Language content object
  const content = {
    en: {
      title: "About HariYoga",
      subtitle: "Welcome to HariYoga – A place to nurture your body, mind, and soul.",
      description:
        "At HariYoga, we believe that yoga is more than just a practice – it's a way of life. Our mission is to empower individuals to achieve holistic wellness through personalized yoga experiences. Whether you are a beginner or an experienced practitioner, we are here to guide you on your journey to physical, mental, and emotional well-being.",
      founderTitle: "Meet Our Founder - Hari Singh Bhupesh",
      cofounderTitle: "Meet Our Co-Founder - Jigar Kalodia",
      backendDeveloperTitle: "Meet Our Backend Developer - Md. Saqulain",
      coreValuesTitle: "Our Core Values",
      coreValues: [
        "Holistic Wellness: Focusing on the well-being of your body, mind, and soul.",
        "Personalized Approach: Tailored yoga plans designed to meet your specific needs and goals.",
        "Community & Support: A supportive community that motivates and guides you every step of the way.",
        "Quality & Expertise: Learn from experienced instructors who provide expert guidance.",
      ],
      visionTitle: "Our Vision",
      visionDescription:
        "Our vision is to create a world where yoga is accessible to everyone. We aim to inspire individuals to live healthier and more mindful lives. Through our online yoga programs, we hope to make the transformative power of yoga available to people across the globe, anytime, anywhere.",
      benefitsTitle: "Benefits of Yoga",
      benefits:
        "Yoga is a holistic practice that offers numerous benefits to both the body and mind. Here are just a few of the many reasons to embrace yoga:",
      joinTitle: "Join Us in the Journey of Wellness",
      joinDescription:
        "Whether you're looking to improve your flexibility, find mental clarity, or embrace a healthier lifestyle, HariYoga is the place for you. Join our vibrant community and embark on a transformative journey towards balance and well-being.",
    },
    hi: {
      title: "हमारे बारे में HariYoga",
      subtitle: "HariYoga में आपका स्वागत है – यह एक स्थान है जहाँ आप अपने शरीर, मन और आत्मा का पोषण कर सकते हैं।",
      description:
        "HariYoga में, हम मानते हैं कि योग केवल एक अभ्यास नहीं है – यह जीवन जीने का तरीका है। हमारा मिशन व्यक्तियों को व्यक्तिगत योग अनुभवों के माध्यम से समग्र कल्याण प्राप्त करने के लिए सशक्त बनाना है। चाहे आप एक शुरुआत करने वाले हों या एक अनुभवी प्रैक्टिशनर, हम यहां हैं आपकी शारीरिक, मानसिक और भावनात्मक भलाई की यात्रा में मार्गदर्शन करने के लिए।",
      founderTitle: "हमारे संस्थापक से मिलें - Hari Singh Bhupesh",
      cofounderTitle: "हमारे सह-संस्थापक से मिलें - Jigar Kalodia",
      backendDeveloperTitle: "हमारे बैकएंड डेवलपर से मिलें - Md. Saqulain",
      coreValuesTitle: "हमारे मुख्य मूल्य",
      coreValues: [
        "समग्र कल्याण: आपके शरीर, मन और आत्मा के कल्याण पर ध्यान केंद्रित करना।",
        "व्यक्तिगत दृष्टिकोण: आपकी विशिष्ट आवश्यकताओं और लक्ष्यों के अनुरूप डिज़ाइन किए गए योग योजनाएँ।",
        "समुदाय और समर्थन: एक सहायक समुदाय जो आपको हर कदम पर प्रेरित और मार्गदर्शन करता है।",
        "गुणवत्ता और विशेषज्ञता: अनुभवी प्रशिक्षकों से सीखें जो विशेषज्ञ मार्गदर्शन प्रदान करते हैं।",
      ],
      visionTitle: "हमारा दृष्टिकोण",
      visionDescription:
        "हमारा दृष्टिकोण यह है कि हम एक ऐसी दुनिया बनाएं जहाँ योग सभी के लिए सुलभ हो। हम व्यक्तियों को स्वस्थ और अधिक जागरूक जीवन जीने के लिए प्रेरित करना चाहते हैं। हमारे ऑनलाइन योग कार्यक्रमों के माध्यम से, हम योग की परिवर्तनकारी शक्ति को दुनिया भर में, कभी भी, कहीं भी उपलब्ध कराना चाहते हैं।",
      benefitsTitle: "योग के लाभ",
      benefits:
        "योग एक समग्र अभ्यास है जो शरीर और मन दोनों के लिए कई लाभ प्रदान करता है। यहां योग को अपनाने के कुछ प्रमुख कारण दिए गए हैं:",
      joinTitle: "कल्याण की यात्रा में हमारे साथ जुड़ें",
      joinDescription:
        "चाहे आप अपनी लचीलापन बढ़ाना चाहते हों, मानसिक स्पष्टता प्राप्त करना चाहते हों, या एक स्वस्थ जीवनशैली को अपनाना चाहते हों, HariYoga आपके लिए सबसे उपयुक्त स्थान है। हमारे जीवंत समुदाय से जुड़ें और संतुलन और भलाई की ओर एक परिवर्तनकारी यात्रा पर निकलें।",
    }
  };

  // Toggle language handler
  const toggleLanguage = () => {
    setLanguage(language === "hi" ? "en" : "hi");
  };

  return (
    <section className="about-us">
      <div className="about-us-content">
        {/* Language Toggle Button */}
        <div className="language-toggle-container">
          <div
            className={`language-toggle ${language === "hi" ? "active" : ""}`}
            onClick={toggleLanguage}
          >
            <div className="language-toggle-text">
              <span className="english">EN</span>
              <span className="hindi">हिंदी</span>
            </div>
          </div>
        </div>

        <h1 className="about-us-title">{content[language].title}</h1>
        <p className="about-us-subtitle">{content[language].subtitle}</p>
        <p className="about-us-description">{content[language].description}</p>

        {/* Founder Section */}
        <div className="founder-info">
          <h2>{content[language].founderTitle}</h2>
          <div className="card mt-20">
            <img
              src="https://media.licdn.com/dms/image/v2/C5103AQG5a0F9zmqyLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550675294739?e=2147483647&v=beta&t=GsrN6RqQB66-mjrhYhVnbabt5KOUjrdX1JoVkSyoJjg"
              alt="Hari Singh Bhupesh"
              className="founder-image"
            />
            <div className="card-body">
              <p>
                Hari Singh Bhupesh is a highly skilled and compassionate yoga instructor with over 15 years of experience in teaching yoga. His dedication to holistic well-being and his deep understanding of the ancient science of yoga make him a trusted guide for students worldwide.
              </p>
            </div>
          </div>
        </div>

        {/* Co-Founder Section */}
        <div className="cofounder-info">
          <h2>{content[language].cofounderTitle}</h2>
          <div className="card mt-20">
            <img
              src="https://media.licdn.com/dms/image/v2/C4D03AQH42QIogbZsuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663787108021?e=2147483647&v=beta&t=OXVZREB5AWt7g6XUkt-pV09nVYRBpd547KU2-5LMxMc"
              alt="Jigar Kalodia"
              className="cofounder-image"
            />
            <div className="card-body">
              <p>
                Jigar Kalodia, co-founder of HariYoga, is passionate about spreading the knowledge of yoga to a broader audience. With a background in tech and digital marketing, Jigar plays a vital role in bringing modern solutions to yoga practices, ensuring that our students enjoy a seamless online experience.
              </p>
            </div>
          </div>
        </div>

        {/* Backend Developer Section */}
        <div className="backend-developer-info">
          <h2>{content[language].backendDeveloperTitle}</h2>
          <div className="card mt-20">
            <img
              src="https://media.licdn.com/dms/image/v2/D5603AQH0oiZwn87c-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722614022107?e=2147483647&v=beta&t=Es_U7ysy77AIQI46Ei35Z1C6Q7AyKa8nDv5x4_q_FnQ" // Replace with Md. Saqulain's image URL
              alt="Md. Saqulain"
              className="backend-developer-image"
            />
            <div className="card-body">
              <p>
                Md. Saqulain is the backend developer at HariYoga, ensuring that our platform runs smoothly and efficiently. His technical expertise and commitment to building robust systems contribute greatly to the success of our online yoga programs.
              </p>
            </div>
          </div>
        </div>

        {/* Core Values Section */}
        <div className="about-us-values">
          <h2>{content[language].coreValuesTitle}</h2>
          <div className="card-container">
            {content[language].coreValues.map((value, index) => (
              <div key={index} className="card">
                <h3>{value.split(":")[0]}</h3>
                <p>{value.split(":")[1]}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Vision Section */}
        <div className="about-us-vision">
          <h2>{content[language].visionTitle}</h2>
          <p>{content[language].visionDescription}</p>
        </div>

        {/* Benefits of Yoga Section */}
        <div className="about-us-benefits">
          <h2>{content[language].benefitsTitle}</h2>
          <img 
            src="https://s3.ap-south-1.amazonaws.com/awsimages.imagesbazaar.com/1200x1800-new/8090/SM351003.jpg" 
            alt="Benefits of Yoga" 
            className="yoga-benefits-image" 
          />
          <p>{content[language].benefits}</p>
        </div>

        {/* Join Us Section */}
        <div className="about-us-join">
          <h2>{content[language].joinTitle}</h2>
          <p>{content[language].joinDescription}</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
