import React from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation
import "./Header.css";
import HariYogaLogo from "../assets/images/hariyogalogo.png";

const Header = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const location = useLocation(); // Get the current location

  const handleLogoClick = () => {
    navigate("/"); // Redirect to homepage
  };

  // Function to determine if a nav link is active
  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <header className="header">
      <div className="logo" onClick={handleLogoClick}> {/* Attach onClick event */}
        <img
          src={HariYogaLogo}
          alt="Hari Yoga Logo"
          className="logo-image"
        />
        <span className="logo-text">
          Hari <span className="logo-highlight">Yoga</span>
        </span>
      </div>
      <nav className="nav">
        <ul className="nav-list">
          <li className="nav-item">
            <a href="/blog" className={`nav-link ${isActive("/blog")}`}>
              Blog
            </a>
          </li>
          <li className="nav-item">
            <a href="/about" className={`nav-link ${isActive("/about")}`}>
              About Us
            </a>
          </li>
          <li className="nav-item">
            <a href="/contact" className={`nav-link ${isActive("/contact")}`}>
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
