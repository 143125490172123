import React, { useState } from "react";
import "./HeroSection.css";
import HariYogaImg from "../assets/images/hariyogaimg.png";
import Popup from "reactjs-popup";  
import { savePhoneNumber, updateUserDetails } from '../api/action';

const HeroSection = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({ name: "", age: "", gender: "" });

  const validatePhoneNumber = (number) => {
    const regex = /^[6789]\d{9}$/;
    return regex.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); 

    if (numericValue.length === 1 && /[012345]/.test(numericValue)) {
      return; 
    }

    setPhoneNumber(numericValue);

    if (validatePhoneNumber(numericValue)) {
      setIsValidNumber(true);
    } else {
      setIsValidNumber(false);
    }
  };

  // const handleBookDemoClick = () => {
  //   if (isValidNumber) {
  //     setIsPopupOpen(true);
  //   }
  // };

//   const handleBookDemoClick = async () => {
//     if (isValidNumber) {
//         try {
//             // Call the backend API
//             const result = await savePhoneNumber(phoneNumber); // Assuming phoneNumber is the current state holding the input

//             if (result.success) {
//                 console.log('Phone number saved successfully!');
//                 setIsPopupOpen(true); // Open the popup on success
//             } else {
//                 console.error('Failed to save phone number:', result.error);
//                 alert(`Error: ${result.error || 'Could not save the phone number'}`);
//             }
//         } catch (error) {
//             console.error('Error occurred:', error);
//             alert('An unexpected error occurred. Please try again.');
//         }
//     } else {
//         alert('Please enter a valid phone number.');
//     }
// };

const handleBookDemoClick = async () => {
  if (isValidNumber) {
      try {
          
          const phoneResult = await savePhoneNumber(phoneNumber);
          if (phoneResult.success) {
              setIsPopupOpen(true);  
          } else {
              console.error('Failed to save phone number:', phoneResult.error);
          }
      } catch (error) {
          console.error('Error occurred:', error);
      }
  } else {
      alert('Please enter a valid phone number.');
  }
};


  
  

  const handleNameChange = (e) => {
    const value = e.target.value;
    const validName = value.replace(/[^a-zA-Z\s]/g, "");

    setName(validName);
  };

  const validatePopupForm = () => {
    let valid = true;
    const newErrors = { name: "", age: "", gender: "" };

    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!name) {
      newErrors.name = "Name is required";
      valid = false;
    } else if (/  /.test(name)) {
      newErrors.name = "Name should not contain consecutive spaces";
      valid = false;
    } else if (!nameRegex.test(name)) {
      newErrors.name = "Name should not contain special characters or numbers";
      valid = false;
    } else if (name.length > 150) {
      newErrors.name = "Name cannot exceed 150 characters";
      valid = false;
    }

    if (!age) {
      newErrors.age = "Age is required";
      valid = false;
    } else if (isNaN(age) || age < 1 || age > 99) {
      newErrors.age = "Age must be a number between 1 and 99";
      valid = false;
    }

    if (!gender) {
      newErrors.gender = "Please select a gender";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   if (validatePopupForm()) {
  //     alert("Form submitted successfully");
  //     setIsPopupOpen(false);
  //   }
  // };

  const handleFormSubmit = async () => {
    try {
        const result = await updateUserDetails(phoneNumber, name, age, gender);
        console.log(result, "result");
        
        if (result.success) {
            alert('User details updated successfully!');
            // Optionally, close the popup or perform further actions
        } else {
            alert(`Failed to update details: ${result.error}`);
        }
    } catch (error) {
        console.error('Error occurred:', error);
        alert('An unexpected error occurred. Please try again.');
    }
};

  return (
    <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title hero-title-one">
          <span className="text-align-center">Welcome to World of</span>  
          <span className="hero-highlight">Mindfulness</span>
        </h1>
        <p className="hero-subtitle text-align-center">With Yoga Get </p>
        <div className="sliding-text">
          
          <p className="hero-subtitle text-align-center hero-highlight flex">
            <span className="scrolling-text">Wellness</span>
            <span className="scrolling-text">Balance</span>
            <span className="scrolling-text">Harmony</span>
            {/* <span className="scrolling-text">Vitality</span> */}
          </p>
        </div>

        <div className="hero-form">
          <div className="hero-input-wrapper">
            <span className="input-prefix">+91</span>
            <input
              type="text"
              placeholder="Enter your mobile number"
              className="hero-input"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength="10"
            />
          </div>
          <button
            className={`hero-button ${isValidNumber ? "highlight" : ""}`}
            onClick={handleBookDemoClick}
            disabled={!isValidNumber}
          >
            Book Demo
          </button>
        </div>
      </div>
      <div className="hero-image">
        <img
          src={HariYogaImg}
          alt="Three animated characters meditating"
          className="hero-image-content"
        />
      </div>

      <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
        <div className="mt-20">
          <span className="xbg" onClick={() => setIsPopupOpen(false)}>
            X
          </span>

          <h3>🎉 Help Us Get to Know You Better 🎉</h3>
          <p className="intro-message">
            Fill in the optional details below, and let us assist you in taking the first step toward a healthier, happier life!
          </p>

          <form className="popup-form" onSubmit={handleFormSubmit}>
            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
              maxLength="150"
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}

            {/* <label htmlFor="age">Age</label> */}
            <input
              type="text"
              id="age"
              placeholder="Enter your age"
              value={age}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,2}$/.test(value)) {
                  setAge(value); 
                }
              }}
              maxLength="2"
              required
            />
            {errors.age && <span className="error">{errors.age}</span>}

            {/* <label htmlFor="gender">Gender</label> */}
            <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)} required>
              <option value="" disabled>
                Select your gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && <span className="error">{errors.gender}</span>}

            <button type="submit" className="close-popup">
              Submit
            </button>
          </form>

          <p className="follow-up-message">
            Our representative will call you back shortly with exciting offers!
          </p>
        </div>
      </Popup>
    </section>
  );
};

export default HeroSection;
