import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Payment.css";
import UpiImg from "../assets/images/upiimg.jpg";

const Payment = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", 
        });
      }, []);
  const location = useLocation();
  const { selectedPlan } = location.state || {}; // Ensure state is passed, else fallback to an empty object

  const [copied, setCopied] = useState(false);
  const [language, setLanguage] = useState("en"); // "en" for English, "hi" for Hindi

  if (!selectedPlan) {
    return <div>No plan selected</div>; // Optional: display a message if no plan is selected
  }
 
  const handleCopyUPI = () => {
    const upiId = "bhupesh279@oksbi"; // Replace with actual UPI ID
    navigator.clipboard.writeText(upiId).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset animation after 2 seconds
    });
  };

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "hi" : "en");
  };

  // Language content object
  const content = {
    en: {
      title: "Payment Details",
      qrMessage: "Scan the QR code to make the payment.",
      upiInstructions: "Tap the UPI ID below to copy it, then pay the displayed amount and send us the screenshot.",
      selectedPlan: "Selected Plan: ",
      amount: "Amount to Pay:",
      contactMessage: "If you are facing any issue, please call us at 9999923304.",
      close: "Close",
      copied: "Copied!"
    },
    hi: {
      title: "भुगतान विवरण",
      qrMessage: "भुगतान करने के लिए क्यूआर कोड स्कैन करें।",
      upiInstructions: "नीचे दिए गए UPI ID पर टैप करें, इसे कॉपी करें, फिर दिखाए गए राशि का भुगतान करें और हमें स्क्रीनशॉट भेजें।",
      selectedPlan: "चुना हुआ प्लान: ",
      amount: "भुगतान करने की राशि:",
      contactMessage: "यदि आपको कोई समस्या आ रही है, तो कृपया हमें 9999923304 पर कॉल करें।",
      close: "बंद करें",
      copied: "कॉपी किया!"
    }
  };

  return (
    <div className="payment-section">
      {/* Language Toggle Button */}
      <div className="language-toggle-container">
        <div
          className={`language-toggle ${language === "hi" ? "active" : ""}`}
          onClick={toggleLanguage}
        >
          <div className="language-toggle-text">
            <span className="english">EN</span>
            <span className="hindi">हिंदी</span>
          </div>
        </div>
      </div>

      <header className="payment-header">
        <h2>{content[language].title}</h2>
        <div className="qr-code-container">
          <img
            src={UpiImg}
            alt="QR Code"
            className="qr-code"
          />
        </div>
        <p>{content[language].qrMessage}</p>
      </header>
  {/* Contact Message */}
  
      {/* UPI Instructions Card */}
      <div className="upi-instructions-card">
        <h3>{content[language].upiInstructions}</h3>
        <div
          className={`upi-id-container ${copied ? "copied" : ""}`}
          onClick={handleCopyUPI}
        >
          <p className="upi-id">bhupesh279@oksbi</p> {/* Replace with actual UPI ID */}
          {copied && <span className="copied-text">{content[language].copied}</span>}
        </div>
      </div>
      <div className="contact-message">
        <p>{content[language].contactMessage}</p>
      </div>
      <div className="payment-details mt-20">
        <h3 className="plan-title">{content[language].selectedPlan} {selectedPlan.name}</h3>
        <p className="amount">
          {content[language].amount} <span className="price">₹{selectedPlan.price}</span>{" "}
          <span className="price-subtext">{selectedPlan.duration}</span>
        </p>
        <ul className="plan-features">
          {selectedPlan.features.map((feature, index) => (
            <li key={index}>✔️ {feature}</li>
          ))}
        </ul>
      </div>

    
    </div>
  );
};

export default Payment;
