import React, { useState } from "react";
import "./ContactUs.css";
import {saveContactFormData} from "../api/action"

const ContactUs = () => {
  // State to track the selected language (English or Hindi)
  const [language, setLanguage] = useState("en");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = language === "en" ? "Name is required." : "नाम आवश्यक है।";
    if (!formData.email) errors.email = language === "en" ? "Email is required." : "ईमेल आवश्यक है।";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = language === "en" ? "Email is invalid." : "ईमेल अवैध है।";
    if (!formData.message) errors.message = language === "en" ? "Message is required." : "संदेश आवश्यक है।";

    return errors;
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const errors = validateForm();
  //   setFormErrors(errors);

  //   if (Object.keys(errors).length === 0) {
  //     setIsSubmitted(true);
  //     // Reset the form after submission
  //     setFormData({
  //       name: "",
  //       email: "",
  //       message: "",
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();  // Prevents the default form submission behavior
    
    // Validate the form data
    const errors = validateForm();
    setFormErrors(errors);
    
    // If there are no errors, submit the form data
    if (Object.keys(errors).length === 0) {
      // Call the API to save the form data
      const response = await saveContactFormData(formData.name, formData.email, formData.message);
      
      // Check if the API response indicates success
      if (response) {
        setIsSubmitted(true);
        alert("Thank you for reaching out! We will get back to you soon.");
        
        // Reset the form data after successful submission
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        // If the response indicates failure, show an error alert
        alert("There was an error while submitting your form. Please try again.");
      }
    }
  };
  

  

  // Language content object
  const content = {
    en: {
      title: "Contact Us",
      subtitle: "We'd love to hear from you! Please fill out the form below, and we'll get back to you shortly.",
      name: "",
      email: "",
      message: "",
      button: "Send Message",
      thankYou: "Thank You!",
      thankYouMessage: "Your message has been submitted successfully. We will contact you soon!",
      close: "Close",
    },
    hi: {
      title: "हमसे संपर्क करें",
      subtitle: "हम आपसे जुड़ने के लिए उत्सुक हैं! कृपया नीचे दिया गया फॉर्म भरें, और हम जल्द ही आपसे संपर्क करेंगे।",
      name: "",
      email: "",
      message: "",
      button: "संदेश भेजें",
      thankYou: "धन्यवाद!",
      thankYouMessage: "आपका संदेश सफलतापूर्वक भेज दिया गया है। हम जल्द ही आपसे संपर्क करेंगे!",
      close: "बंद करें",
    }
  };

  // Toggle language handler
  const toggleLanguage = () => {
    setLanguage(language === "en" ? "hi" : "en");
  };

  return (
    <section className="contact-us">
      <div className="contact-us-content">
        {/* Language Toggle Button */}
        <div className="language-toggle-container">
          <div
            className={`language-toggle ${language === "hi" ? "active" : ""}`}
            onClick={toggleLanguage}
          >
            <div className="language-toggle-text">
              <span className="english">EN</span>
              <span className="hindi">हिंदी</span>
            </div>
          </div>
        </div>

        <h1 className="contact-us-title">{content[language].title}</h1>
        <p className="contact-us-subtitle">
          {content[language].subtitle}
        </p>

        {/* Contact Form */}
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">{content[language].name}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={formErrors.name ? "input-error" : ""}
                placeholder={language === "en" ? "Enter your name" : "अपना नाम दर्ज करें"}
              />
              {formErrors.name && <p className="error-message">{formErrors.name}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="email">{content[language].email}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={formErrors.email ? "input-error" : ""}
                placeholder={language === "en" ? "Enter your email" : "अपना ईमेल दर्ज करें"}
              />
              {formErrors.email && <p className="error-message">{formErrors.email}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="message">{content[language].message}</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className={formErrors.message ? "input-error" : ""}
                placeholder={language === "en" ? "Enter your message" : "अपना संदेश दर्ज करें"}
              />
              {formErrors.message && <p className="error-message">{formErrors.message}</p>}
            </div>

            <button type="submit" className="submit-button">{content[language].button}</button>
          </form>
        </div>

        {/* Popup Message */}
        {isSubmitted && (
          <div className="popup">
            <div className="popup-content">
              <h2>{content[language].thankYou}</h2>
              <p>{content[language].thankYouMessage}</p>
              <button onClick={() => setIsSubmitted(false)} className="close-popup">{content[language].close}</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactUs;
