import React from 'react';
import './footer.css';
import HariYogaLogo from '../assets/images/hariyogalogo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={HariYogaLogo} alt="HariYoga Logo" className="logo" />
          <h3>HariYoga</h3>
        </div>

        <div className="footer-links">
          <div className="footer-column">
            <h4>COMPANY</h4>
            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Blog</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>PROGRAMS</h4>
            <ul>
              <li><a href="#">Yoga</a></li>
              <li><a href="#">Personal Batch</a></li>
              <li><a href="#">Family Batch</a></li>
              <li><a href="#">Weekend Batch</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>POLICIES</h4>
            <ul>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a href="#">Refund Policy</a></li>
              <li><a href="#">Fair Practice Code</a></li>
            </ul>
          </div>

          <div className="footer-contact">
  <h4>Contact Us</h4>
  <p>If you're facing any issue, feel free to <a href="tel:+9999923304">call us at 9999923304</a></p>
  <div className="social-icons">
    <a href="https://www.facebook.com/" aria-label="Facebook">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAgVBMVEUAAAAQcP8IZf8IZ/8JZv8HZf8IZv8IZv8IaP8JZ/8HZv8IZv8FZf8YcP9FjP+TvP/g7P/////R4/9Vlf8QYP+Es/9kn/8IZv8nef8JZf8AYP/v9f/Q4v/B2P9GjP8HZv+yz//Q4/83g/8HZv/g6/+Dsv8HZf/n7//////////e6//ZLyHjAAAAK3RSTlMAEGCfz+//XyCQj98w/////////xD//6D/kBD/////7////8///5Cgz+/vONkvXQAAAPJJREFUeAF9kkUCwzAMBGVSGMrM3P//rxBaB+e6s0YREFJpw2y0cgS1cT3DQLmNWPjcwK/XA24RWIuEdg4j7OtHUX0NYedxko5+jCeZMc0En8FsVDDHSd1WDoFdIlogX46awopozWA+ythsd7s9ZxymJBkcs3wcMZC0YHDKhDNbKLowuGYC21zINIWUbQ7EwwJT7YogqgTTKaTY4tIp7HDIRadwwzVlKVyv11HG9cekFBxam8FbTInuQ4LCd3cL2Uzd+4UV/VkHfUIgMLRdQuBi7JsCxh5rQEAfrO9NYSWojruwBOOhDoR8PF+j0fuipNX+AmbCIviMIiwCAAAAAElFTkSuQmCC" alt="Facebook" />
    </a>
    <a href="https://twitter.com/" aria-label="Twitter">
      <img src="https://upload.wikimedia.org/wikipedia/commons/b/b7/X_logo.jpg" alt="Twitter" />
    </a>
    <a href="https://www.instagram.com/" aria-label="Instagram">
      <img src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg" alt="Instagram" />
    </a>
  </div>
</div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
