import React, { useState } from "react";
import "./BlogPage.css";

const BlogPage = () => {
  // State to track the selected language (English or Hindi)
  const [language, setLanguage] = useState("en");

  // Language content object
  const content = {
    en: {
      title: "Discover Inner Peace with Hari Yoga",
      intro: "At Hari Yoga, we offer a range of meditation classes designed to nurture your body, mind, and soul. Whether you are a beginner or an experienced practitioner, our sessions provide a calming and transformative experience.",
      benefitsTitle: "Benefits of Meditation",
      benefitsDescription: "Meditation at Hari Yoga brings you closer to a state of mental clarity and spiritual fulfillment. Here are some of the life-changing benefits of joining our meditation classes:",
      benefitsList: [
        "Reduces stress and anxiety",
        "Improves mental focus and clarity",
        "Enhances emotional resilience",
        "Promotes overall mental and physical health",
        "Deepens spiritual awareness and growth"
      ],
      teacherTitle: "Meet Our Expert: Hari Singh Bhuspesh",
      teacherDescription: "With over 23+ years of experience, Hari Singh Bhuspesh has guided countless individuals toward peace, clarity, and spiritual well-being. His dedication to the art of meditation has earned him a reputation as a true master of inner transformation.",
      ctaTitle: "Begin Your Meditation Journey Today",
      ctaDescription: "Take the first step toward a balanced and peaceful life. Join our meditation classes and experience the transformation firsthand.",
      ctaButton: "Book Your Class"
    },
    hi: {
      title: "हरी योग के साथ आंतरिक शांति की खोज करें",
      intro: "हरी योग में, हम एक श्रृंखला योग कक्षाएं प्रदान करते हैं जो आपके शरीर, मन और आत्मा को पोषित करने के लिए डिज़ाइन की गई हैं। चाहे आप एक शुरुआत करने वाले हों या एक अनुभवी साधक, हमारे सत्र आपको एक शांति और परिवर्तनकारी अनुभव प्रदान करते हैं।",
      benefitsTitle: "ध्यान के लाभ",
      benefitsDescription: "हरी योग में ध्यान आपको मानसिक स्पष्टता और आध्यात्मिक संतोष की स्थिति के करीब लाता है। यहां हमारे ध्यान कक्षाओं में शामिल होने के कुछ जीवन परिवर्तक लाभ हैं:",
      benefitsList: [
        "तनाव और चिंता को कम करता है",
        "मानसिक फोकस और स्पष्टता को बेहतर बनाता है",
        "भावनात्मक लचीलापन बढ़ाता है",
        "कुल मानसिक और शारीरिक स्वास्थ्य को बढ़ावा देता है",
        "आध्यात्मिक जागरूकता और विकास को गहरा करता है"
      ],
      teacherTitle: "हमारे विशेषज्ञ से मिलें: हरि सिंह भूपेश",
      teacherDescription: "23+ वर्षों के अनुभव के साथ, हरि सिंह भूपेश ने अनगिनत व्यक्तियों को शांति, स्पष्टता और आध्यात्मिक कल्याण की ओर मार्गदर्शन किया है। ध्यान की कला के प्रति उनकी समर्पण ने उन्हें आंतरिक परिवर्तन के असली उस्ताद के रूप में पहचान दिलाई है।",
      ctaTitle: "आज ही अपनी ध्यान यात्रा शुरू करें",
      ctaDescription: "एक संतुलित और शांति जीवन की ओर पहला कदम बढ़ाएं। हमारे ध्यान कक्षाओं में शामिल हों और परिवर्तन का अनुभव करें।",
      ctaButton: "अपनी कक्षा बुक करें"
    }
  };

  // Toggle language handler
  const toggleLanguage = () => {
    setLanguage(language === "en" ? "hi" : "en");
  };

  return (
    <div className="blog-container">
      {/* Language Toggle Button */}
      <div className="language-toggle-container">
        <div
          className={`language-toggle ${language === "hi" ? "active" : ""}`}
          onClick={toggleLanguage}
        >
          <div className="language-toggle-text">
            <span className="english">EN</span>
            <span className="hindi">हिंदी</span>
          </div>
        </div>
      </div>

      <h1 className="blog-title">{content[language].title}</h1>
      <p className="blog-intro">{content[language].intro}</p>

      <div className="image-section">
        <img
          src="https://asset.gecdesigns.com/img/wallpapers/yoga-day-wallpaper-yoga-meditation-with-beautiful-forest-background-sr18062414-cover.webp"
          alt="Meditation in a peaceful forest setting"
          className="blog-image"
        />
      </div>

      <section className="class-details">
        <h2 className="section-heading">{content[language].benefitsTitle}</h2>
        <p className="section-description">{content[language].benefitsDescription}</p>
        <ul className="benefits-list">
          {content[language].benefitsList.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      </section>

      <div className="teacher-section">
        <h2 className="section-heading">{content[language].teacherTitle}</h2>
        <div className="teacher-profile">
          <img
            src="https://media.licdn.com/dms/image/v2/C5103AQG5a0F9zmqyLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550675294739?e=2147483647&v=beta&t=GsrN6RqQB66-mjrhYhVnbabt5KOUjrdX1JoVkSyoJjg"
            alt="Hari Singh Bhuspesh"
            className="teacher-image"
          />
          <p className="teacher-description">{content[language].teacherDescription}</p>
        </div>
      </div>

      <section className="call-to-action">
        <h3 className="cta-heading">{content[language].ctaTitle}</h3>
        <p className="cta-description">{content[language].ctaDescription}</p>
        <button onClick={() => (window.location.href = "/contact")} className="cta-button">
          {content[language].ctaButton}
        </button>
      </section>
    </div>
  );
};

export default BlogPage;
