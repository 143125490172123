import React from 'react';
import './Schedule.css';

const scheduleData = [
  {
    day: "Monday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  },
  {
    day: "Tuesday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  },
  {
    day: "Wednesday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  },
  {
    day: "Thursday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  },
  {
    day: "Friday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  },
  {
    day: "Saturday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  },
  {
    day: "Sunday",
    shifts: [
      { shift: "Morning Yoga", time: "6:00 AM - 7:30 AM", instructor: "Hari Singh Bhupesh" },
      { shift: "Evening Yoga", time: "5:00 PM - 6:30 PM", instructor: "Hari Singh Bhupesh" }
    ]
  }
];

const Schedule = () => {
  return (
    <div className="schedule-container">
      <h1 className="schedule-heading">Yoga Batch Schedule</h1>
      {scheduleData.map((daySchedule, index) => (
        <div className="day-schedule" key={index}>
          <h2 className="day-name">{daySchedule.day}</h2>
          <div className="shifts">
            {daySchedule.shifts.map((shift, i) => (
              <div className="shift" key={i}>
                <h3 className="shift-name">{shift.shift}</h3>
                <p className="shift-time">{shift.time}</p>
                <p className="shift-instructor">Instructor: {shift.instructor}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Schedule;
