import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "./Resourcesection.css";
import {savePopupFormData} from "../api/action"

const ResourcesSection = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [feeling, setFeeling] = useState("");
  const [yogaPractice, setYogaPractice] = useState("");
  const [errors, setErrors] = useState({});
  
  const navigate = useNavigate();

  const plans = [
    {
      name: "Individual Plan",
      price: 299,
      duration: "per month",
      features: [
        "2 online classes a week",
        "Part elite Yoga group access",
        "Grouped QNA session",
        "Daily Class Alerts",
        "Max Batch strength 25"
      ]
    },
    {
      name: "Professional 6 Month Plan (Only ~₹99/month)",
      price: 99 * 6,
      duration: "6 months  - Bestseller",
      features: [
        "2 online classes per week",
        "Access to elite Yoga group",
        "Grouped Q&A sessions",
        "Daily class alerts",
        "Maximum batch strength: 25 students"
      ]
    }
  ];

  const handleCardClick = (plan) => {
    setSelectedCard(plan);
    navigate("/payment", { state: { selectedPlan: plan } });
  };

  const validatePhoneNumber = (number) => {
    const phonePattern = /^[7-9]\d{9}$/;  // Starts with 7, 8, or 9 and followed by 9 digits
    return phonePattern.test(number);
  };

  const handlePhoneChange = (e) => {
    let input = e.target.value;
    // Allow only digits and prevent anything else
    input = input.replace(/[^0-9]/g, "");
    setPhoneNumber(input);
  };

  // Handle Name input change with validation
const handleNameChange = (e) => {
  let input = e.target.value;

  // Remove non-alphabetical characters (allow spaces)
  input = input.replace(/[^a-zA-Z\s]/g, "");

  // Prevent consecutive spaces
  input = input.replace(/\s{2,}/g, " ");

  // Set the updated value to the state
  setName(input);
};


  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate Name: Ensure no digits and consecutive spaces
    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(name)) {
      newErrors.name = "Name can only contain alphabetic characters";
      isValid = false;
    } else if (/\s{2,}/.test(name)) {
      newErrors.name = "Name cannot have consecutive spaces";
      isValid = false;
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!validatePhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = "Enter a valid 10-digit phone number starting with 7, 8, or 9";
      isValid = false;
    }

    if (!feeling) {
      newErrors.feeling = "Please select how you are feeling";
      isValid = false;
    }

    if (!yogaPractice) {
      newErrors.yogaPractice = "Please select if you practice yoga daily";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     alert("Our team will contact you soon. Stay tuned for Hari Yoga!");
  //     setIsPopupOpen(false); // Close the popup on successful submission
  //   }
  // };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (validateForm()) {
        // Prepare the data
        const formData = {
            name,
            phoneNumber,
            feeling,
            yogaPractice,
        };

        try {
            // Call the API
            const response = await savePopupFormData(
                formData.name,
                formData.phoneNumber,
                formData.feeling,
                formData.yogaPractice
            );

            if (response) {
                alert('Our team will contact you soon. Stay tuned for Hari Yoga!');
                setIsPopupOpen(false); // Close the popup on successful submission
                // Optionally clear the form fields
                setName('');
                setPhoneNumber('');
                setFeeling('');
                setYogaPractice('');
            } else {
                alert(`Failed to save form data: ${response.error}`);
            }
        } catch (error) {
            console.error('Error while submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    } else {
        alert('Please correct the errors in the form before submitting.');
    }
};

  return (
    <div className="pricing-section">
      <h2 className="pricing-title">Choose the one that Fits!</h2>
      <div className="pricing-container">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-card ${selectedCard?.name === plan.name ? "highlighted-card" : ""}`}
            onClick={() => handleCardClick(plan)}
          >
            <h3 className="plan-title">{plan.name}</h3>
            <p className="price">
              ₹{plan.price} <span className="price-subtext">/{plan.duration}</span>
            </p>
            <button className="get-started-btn">Get Started</button>
            <ul className="plan-features">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>✔️ {feature}</li>
              ))}
            </ul>
          </div>
        ))}

        <div className="demo-card">
          <h3>Not Sure?</h3>
          <p>Book a Demo Call With Us! Our team will help you in selecting the best plan according to your needs.</p>
          <button className="demo-btn" onClick={() => setIsPopupOpen(true)}>Book Demo</button>
        </div>

        <div className="coming-soon-card">
          <h3>More Plans Coming Soon!</h3>
        </div>
      </div>

      <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
        <div className="">
          <h3>Book a Demo Call</h3>
          <form onSubmit={handleFormSubmit}>
            <label htmlFor="feeling">How are you feeling now?</label>
            <select id="feeling" value={feeling} onChange={(e) => setFeeling(e.target.value)} required>
              <option value="" disabled>Select</option>
              <option value="good">Good</option>
              <option value="bad">Bad</option>
              <option value="very bad">Very Bad</option>
            </select>
            {errors.feeling && <span className="error">{errors.feeling}</span>}

            <label htmlFor="yoga">Do you practice yoga daily?</label>
            <select id="yoga" value={yogaPractice} onChange={(e) => setYogaPractice(e.target.value)} required>
              <option value="" disabled>Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            {errors.yogaPractice && <span className="error">{errors.yogaPractice}</span>}

            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              id="name"
              placeholder="Enter Name"
              value={name}
              onChange={handleNameChange}  // Use handleNameChange here
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}

            {/* <label htmlFor="phone">Phone Number</label> */}
            <input
              type="text"
              id="phone"
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={handlePhoneChange}  // Use handlePhoneChange here
              required
              maxLength="10"
            />
            {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}

            <button type="submit" className="book-call-btn">Book Call</button>
          </form>
        </div>
      </Popup>
    </div>
  );
};

export default ResourcesSection;
